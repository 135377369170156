import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="parma1848">
<h1>Ambasciatore sabaudo</h1>
<p>Il secondo periodo londinese di Antonio sembra condurre a un avvicinamento definitivo tra Gallenga e Giuseppe Mazzini, consolidato dalla partecipazione di Antonio alla costituzione dell’Associazione Nazionale Italiana, il 10 agosto 1847.<br />
Tale associazione si propone di raccogliere fondi per il sostegno della causa italiana e delle guerre d’indipendenza.<br />
Il 27 marzo 1848, a seguito delle insurrezioni milanesi, Antonio parte insieme a Mazzini per l’Italia;<br />
tuttavia, giunti a Milano, i due, in disaccordo rispetto al ruolo del Regno di Sardegna nelle vicende italiane, si separano.</p>
<ImgSinistra nome="pilotta.PNG" alt="pilotta" didascalia="Palazzo della Pilotta (PR)"/>
<p>Antonio cerca invano di arruolarsi nell’esercito piemontese di stanza tra Mantova e Verona, finché non incontra un gruppo di volontari parmigiani capitanati da suo fratello Giuseppe Gallenga (1819-1820).</p>
<p>Il fratello lo convince a partecipare alla guerra non con le armi ma indirettamente, attraverso la sua attività di scrittore e giornalista.<br />
Abbandonata la causa mazziniana, decide così di recarsi a Parma per promuovere l’annessione immediata del Ducato di Parma, Piacenza e Guastalla al Regno di Sardegna, annessione per la quale si dovranno attendere altri dodici anni.</p>
<ImgCentro nome="cartinaducato.png" alt="ducato" didascalia="Cartina del Ducato di Parma, Piacenza e Guastalla"/>
<p>Giunge in seguito a Torino, dove sceglie di schierarsi dalla parte dei conservatori di Cesare Alfieri di Sostegno, fazione al governo in quel momento; nello stesso periodo scrive per il quotidiano &quot;Il Risorgimento&quot; alcuni articoli per i quali lo stesso Conte di Cavour lo ringrazierà personalmente.</p>
<p>Cavalcando la grande popolarità del momento, nell’ottobre del 1848 accetta di svolgere una delicata missione diplomatica a Francoforte per trovare appoggi alla causa dell’indipendenza italiana presso la Confederazione Germanica.</p>
<ImgCentro nome="francoforte.png" alt="francoforte" didascalia="Francoforte"/>
<p>Tuttavia, la missione si rivela un fallimento quando Antonio decide di proporre di propria iniziativa un’intesa tra Piemonte e Austria che per poco non fa perdere al Piemonte l’appoggio di Francia e Inghilterra.</p>
<p>Il 6 dicembre 1848 Torino gli comunica la fine immediata della missione.</p>
<ImgDestra nome="stemma.png" alt="stemma" didascalia="Stemma del Ducato di Parma, Piacenza e Guastalla"/>
<h2>Link per approfondire</h2>
<p><A href="https://it.wikipedia.org/wiki/Associazione_Nazionale_Italiana">“Associazione nazionale italiana”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/camillo-benso-conte-di-cavour/">“Camillo Benso (Conte di Cavour)”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/alfieri-di-sostegno-cesare_(Dizionario-Biografico)/">“Cesare Alfieri di Sostegno”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/confederazione-germanica/">“Confederazione Germanica”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/parma-e-piacenza-ducato-di_%28Enciclopedia-Italiana%29/">“Ducato di Parma, Piacenza e Guastalla”</A></p>

	</PaginaTesto>
}